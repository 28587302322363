import React, { useEffect } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/animation.json";

const Splash = () => {
  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.documentElement.style.overflow = "unset";
    };
  }, []);

  return (
    <div style={{ width: 350 }}>
      <Lottie animationData={animationData} autoplay loop />
    </div>
  );
};

export default Splash;
