import React from "react";
import {
  MenuContainer,
  ModalHeader,
  ModalFooter,
  Column,
  Content,
  LogoutContainer,
  StyledText,
  ButtonLogin,
  Buttons,
  ButtonSignup,
  FullNameText,
} from "./SmallScreensSideBarModal.styles";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import menu4 from "react-useanimations/lib/menu4";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import LocaleSelection from "../LocaleSelection/LocaleSelection";
import Icon from "../utils/Icon";

const SmallScreensSideBarModal = ({
  open,
  close,
  setOpenSidebarModal,
  openSidebarModal,
  loggedIn,
  ar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fullName = "Srdjan Uzunovic";

  const navigateTo = (route) => {
    navigate(route);
    setOpenSidebarModal(false);
  };

  const handleLogout = () => {};

  return (
    <Modal
      onClose={close}
      open={open}
      right
      width={"60%"}
      height={"100%"}
      borderRadius={ar ? "0px 30px 0px 0px" : "30px 0px 0px 0px"}
      bg={"#85a1ac"}
      blur={"3.5px"}
      paddingOuter={0}
      className={"responsive"}
    >
      <Column>
        <ModalHeader>
          <MenuContainer>
            <UseAnimations
              onClick={() => {
                setOpenSidebarModal(!openSidebarModal);
              }}
              className="pointer"
              animation={menu4}
              reverse
              size={42}
              strokeColor="#85a1ac"
            />
          </MenuContainer>
          {loggedIn && (
            <Buttons>
              <LocaleSelection color />
              <FullNameText onClick={() => navigateTo("/")} $ar={ar}>
                {t("hello")}, {t(`${fullName}`)}
              </FullNameText>
            </Buttons>
          )}
        </ModalHeader>

        <Content></Content>
      </Column>

      <ModalFooter $gap={loggedIn}>
        {loggedIn ? (
          <Buttons $gap>
            <LogoutContainer>
              <StyledText onClick={handleLogout}>{t("logout")}</StyledText>
              <Icon icon={<LogoutIcon />} selectedLocale={ar} />
            </LogoutContainer>
            <ButtonSignup onClick={() => navigateTo("/")}>
              <Icon
                icon={<DashboardIcon style={{ marginBottom: -3 }} />}
                selectedLocale={ar}
              />
            </ButtonSignup>
          </Buttons>
        ) : (
          <Buttons>
            <ButtonLogin
              onClick={() => {
                // navigateTo("/log-in");
              }}
            >
              {t("log-in")}
            </ButtonLogin>
            <ButtonSignup
              onClick={() => {
                navigateTo("/sign-up");
              }}
            >
              {t("sign-up")}
            </ButtonSignup>
          </Buttons>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SmallScreensSideBarModal;
