import { styled } from "styled-components";
import ProgressiveImg from "../../components/ProgressiveImg/ProgressiveImg";
import { ReactComponent as GiftSvg } from "../../assets/icons/gift.svg";

export const HomePageContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0px 0px 30px 30px;

  min-height: calc(100dvh - 265px - 122px);
`;

export const Text2 = styled.h3`
  user-select: none;
  color: #85a1ac;
  text-align: center;
  font-family: "FrankfurterEF";
  font-size: 50px;
  font-weight: 500;
  line-height: 32px; /* 64% */
  @media (max-width: 1100px) {
    text-align: center;
  }
  @media (max-width: 615px) {
    font-size: 48px;
  }
  @media (max-width: 410px) {
    font-size: 32px;
  }
`;

export const Sponsors = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const SectionTwo = styled.div`
  width: 1440px;
  height: 60vh;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
  padding: 2rem;

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 602px) {
    height: unset;
  }
`;

export const SectionTwoContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const SponsorImgFirst = styled.img`
  user-select: none;
`;
export const SponsorImg = styled(SponsorImgFirst)`
  @media (max-width: 600px) {
    width: ${(props) => (props.$small ? "250px" : "100%")};
    max-height: 175px;
  }
`;

export const HomeContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

export const HeaderWrapper = styled.header`
  background: #85a1ac;
  z-index: 500;
  position: fixed;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 1440px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    padding: 5rem 2rem 2rem 2rem;
    gap: 50px;
  }
  @media (max-width: 410px) {
    padding: 8rem 2rem 2rem 2rem;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 110px;
  width: 61rem;
  @media (max-width: 1100px) {
    gap: 50px;
  }
  @media (max-width: 615px) {
    width: 100%;
  }
`;
export const GiftIcon = styled.div`
  align-self: center;
  transform: rotate(18.884deg);

  display: none;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  align-items: center;
`;

export const H1 = styled.h1`
  color: #85a1ac;
  width: 550px;
  user-select: none;
  font-family: "Avenir-Black";
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 1100px) {
    text-align: center;
  }
  @media (max-width: 615px) {
    font-size: 48px;
    width: 100%;
  }
  @media (max-width: 410px) {
    font-size: 32px;
  }
`;
export const StyledH1 = styled.h1`
  color: #fff;

  user-select: none;
  font-family: "Avenir-Book";
  font-size: 64px;
  @media (max-width: 1100px) {
    text-align: center;
  }
  @media (max-width: 615px) {
    font-size: 48px;
  }
  @media (max-width: 410px) {
    font-size: 32px;
  }
`;

export const Text = styled.h2`
  user-select: none;

  color: #fff;
  width: 555px;
  font-family: "Avenir-Medium";
  font-size: 24px;
  line-height: 32px; /* 133.333% */
  @media (max-width: 1100px) {
    text-align: center;
  }
  @media (max-width: 615px) {
    font-size: 18px;
    line-height: 18px;
    width: 100%;
  }
  @media (max-width: 410px) {
    font-size: 14px;
    padding: 0 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;
  @media (max-width: 1100px) {
    justify-content: center;
  }
  @media (max-width: 615px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  user-select: none;
  align-items: center;
  border-radius: 30px;
  background: #f0f0f0;
  box-shadow: 0px 12px 24px -6px rgba(0, 0, 0, 0.5);
  min-width: 234px;
  min-height: 61px;
  justify-content: center;
  border: none;

  cursor: pointer;
  color: #85a1ac;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 22px;
  letter-spacing: 0.11px;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 615px) {
    width: 48%;
    font-size: 18px;
  }
  @media (max-width: 410px) {
    font-size: 14px;
    min-height: 51px;
    min-width: 184px;
  }
`;

export const StyledImage = styled(ProgressiveImg)`
  user-select: none;
  z-index: 400;
  border-radius: 50px;
  box-shadow: -2px 2px 30px 12px rgba(0, 0, 0, 0.25);
  @media (max-width: 615px) {
    width: 311px;
    height: 467px;
  }
  @media (max-width: 410px) {
    width: 259px;
    height: 409px;
  }
`;

export const ButtonStroke = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  border-radius: 30px;
  user-select: none;
  border: 1px solid #fff;
  background: transparent;
  min-width: 234px;
  min-height: 61px;
  justify-content: center;

  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 22px;

  letter-spacing: 0.11px;
  &:active {
    transition: all 0.2s ease-in-out;
    scale: 0.95;
  }
  @media (max-width: 615px) {
    width: 48%;
    font-size: 18px;
  }
  @media (max-width: 410px) {
    font-size: 14px;
    min-height: 51px;
    min-width: 184px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  @media (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }
`;

export const RotateGiftIcon = styled.div`
  transform: ${(props) =>
    props.$ar ? "rotate(-18.884deg)" : "rotate(18.884deg)"};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  align-self: flex-end;
`;

export const Gift = styled(GiftSvg)`
  height: 60px;
  width: 60px;
  @media (max-width: 410px) {
    height: 30px;
    width: 30px;
  }
`;
