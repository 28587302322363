import { createSlice } from "@reduxjs/toolkit";

import {
  countClickActionBuilder,
  getBusinessRulesVariablesBuilder,
} from "./builder";

import { SESSION_STORAGE_SELECTED_LOCALE } from "../../../utils/constants";

const commonSlice = createSlice({
  name: "commonInfo",
  initialState: {
    selectedLocale:
      localStorage.getItem(SESSION_STORAGE_SELECTED_LOCALE) ?? "ar",
    countryCode: "SA",
  },
  reducers: {
    setSelectedLocale: (state, action) => {
      state.selectedLocale = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getBusinessRulesVariablesBuilder(builder);
    // countClickActionBuilder(builder);
  },
});

export const { setSelectedLocale, setCountryCode } = commonSlice.actions;

export const selectedLocale = (state) => state.commonInfo.selectedLocale;

export default commonSlice.reducer;
