import styled, { keyframes } from "styled-components";
import { ReactComponent as DashboardSvg } from "../../assets/icons/dashboard.svg";

import { NavLink } from "react-router-dom";

export const Dashboard = styled(DashboardSvg)`
  path {
    fill: #fff;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const MenuContainer = styled.div`
  width: 42px;
  height: 42px;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  /* flex-wrap: wrap; */
  width: 100%;
`;

export const CartContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
`;

export const SearchContainer = styled.div`
  display: flex;
  border-bottom: ${(props) =>
    props.$open ? "1px solid white" : "1px solid transparent"};
  width: ${(props) => (props.$open ? "25rem" : "4.5rem")};
  margin-right: ${(props) => (props.$open ? "0" : "-1.5rem")};
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
`;
export const SearchInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 1rem;
  user-select: none;
  color: #fff;
  font-family: "Avenir";
  font-weight: 500;
  font-size: 16px;
  &::placeholder {
    font-weight: 400;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  margin-left: ${(props) => (props.$ar ? "unset" : "auto")};
  margin-right: ${(props) => (props.$ar ? "auto" : "unset")};
  justify-content: center;
  flex-wrap: wrap;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${(props) => (props.$gap ? "3rem" : "0rem")};
`;

export const StyledText = styled.span`
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.18px;
  min-width: fit-content;
  text-align: center;

  &:active {
    opacity: 0.5;
  }
`;
export const Text = styled.span`
  user-select: none;
  color: ${(props) => (props.$color ? "#fff" : "#484888")};
  font-family: "Avenir";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.18px;
  min-width: 12.8rem;
  text-align: center;

  &:active {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: unset;
  outline: unset;
  user-select: none;
  background-color: #fff;
  transition: transform 0.3s ease;
  box-shadow: 0px 1.2rem 24rem -0.6rem rgba(81, 81, 145, 0.460922);
  border-radius: 3rem;
  padding: 1rem 3rem;
  letter-spacing: 0.5%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 12.8rem;

  &:hover {
    box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba(81, 81, 145, 0.80922);
    transform: scale(1.05);
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => (props.$gap ? "2rem" : "0rem")};
`;
export const ButtonLogin = styled.button`
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 106px;
  user-select: none;
  width: 119px;
  height: 49px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  @media (max-width: 410px) {
    font-size: 14px;
    height: 39px;
    width: 80px;
  }
`;
export const ButtonSignup = styled.button`
  color: #85a1ac;
  font-family: "Avenir-Medium";
  font-size: 18px;
  background: #fff;
  border: none;
  user-select: none;
  outline: none;
  cursor: pointer;
  border-radius: 106px;
  width: 119px;
  height: 49px;
  align-items: center;
  justify-content: center;
  @media (max-width: 410px) {
    font-size: 14px;
    height: 39px;
    width: 100px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
  }

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-height: 1090px) {
    gap: 2rem;
    padding-right: 0.5rem;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
  padding-right: 10px;
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.$open ? "100%" : "33px")};
`;
export const Link = styled.span`
  color: #fff;
  font-family: "Avenir";
  user-select: none;
  font-size: 20px;
  font-weight: 500;

  letter-spacing: 0.067px;
`;
export const StyledLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  &:hover {
    padding-left: 2rem;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;
export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledColumn = styled(Column)`
  gap: 0rem;
  padding: 2rem;
  max-height: 55rem;
  overflow: auto;
  animation: ${(props) => (props.$open ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;
export const FullNameText = styled.span`
  user-select: none;
  text-align: left;
  margin-bottom: -2px;
  margin-left: ${(props) => (props.$ar ? "0rem" : "2rem")};
  margin-right: ${(props) => (props.$ar ? "2rem" : "0rem")};
  color: #fff;
  font-family: "Avenir-Light";
  font-size: 18px;
  letter-spacing: -0.18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  gap: 1rem;

  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;

export const StyledNavLink = styled(NavLink)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6;
  text-decoration: none;
  color: #fff;
  font-family: "Avenir-Medium";
  gap: 15px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.0666667px;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  &.active {
    opacity: 1;
    padding-left: 20px;
  }

  &:hover {
    padding-left: 20px;
    opacity: 1;
    background-color: #fff2;
  }
  @media (max-height: 545px) {
    padding: 5px;
    padding-left: 10px;
  }
`;
