export const APPLICATION_NAME = "YGIICSR";
export const APPLICATION_NAME_NORMAL = "Ygii - Corporate Social Responsibility";
export const APP_VERSION = "2.0.0";

export const LOCAL_STORAGE_ACCESS_TOKEN = `${APPLICATION_NAME}_ACCESS_TOKEN`;
export const LOCAL_STORAGE_REFRESH_TOKEN = `${APPLICATION_NAME}_REFRESH_TOKEN`;

export const LOCAL_STORAGE_PHONE_COUNTRY = `${APPLICATION_NAME}_PHONE_COUNTRY`;
export const LOCAL_STORAGE_SELECTED_LOCALE = `${APPLICATION_NAME}_SELECTED_LOCALE`;
export const SESSION_STORAGE_SELECTED_LOCALE = `${APPLICATION_NAME}_SELECTED_LOCALE`;
export const googlePlacesKey = "AIzaSyCR388mWBleCqk4xOU2ZBjoBM_Aiu6VNt4";

export const SPLASH_SCREEN_DURATION_IN_MILLIS = 400;

export const keyboardTypeEnum = {
  default: "default",
  email: "email-address",
  numeric: "number-pad",
  decimal: "decimal-pad",
  phone: "phone-pad",
};
