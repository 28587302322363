import { axiosAuthClient } from "..";
import { handleError } from "../../utils/errors";

class AuthService {
  signUpOrganization = async ({
    email,
    password,
    firstName,
    lastName,
    name,
    phoneNumber,
  }) => {
    try {
      const body = {
        email,
        password,
        firstName,
        lastName,
        name,
        phoneNumber,
      };
      const response = await axiosAuthClient.post("/sign-up", body, {
        withCredentials: true,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { organization } = response.data.data;
      return { organization };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while creating new organization, status:" + err.code
      );
    }
  };

  login = async ({ email, password }) => {
    try {
      const response = await axiosAuthClient.post(
        "/login",
        {
          email,
          password,
        },
        { withCredentials: true }
      );
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, businessUser } = response.data.data;

      return { accessToken, refreshToken, businessUser };
    } catch (err) {
      throw handleError(
        err,
        "Error occurred while logging in, status:" + err.code
      );
    }
  };

  logout = async () => {
    try {
      const response = await axiosAuthClient.post(
        "/logout",
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return;
    } catch (err) {
      throw handleError(err, "Error occurred while logging out");
    }
  };
}
const authService = new AuthService();

export default authService;
