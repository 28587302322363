import React, { useEffect } from "react";

import Lottie from "lottie-react";
import animationData from "../../assets/animations/animation.json";
import { Loading, LoadingSize } from "./Loader.styles";

const Loader = ({ disableScroll }) => {
  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    if (!disableScroll) {
      window.scrollTo(0, 0);
    }

    return () => {
      document.documentElement.style.overflow = "unset";
    };
  }, []);

  return (
    <Loading>
      <LoadingSize>
        <Lottie animationData={animationData} autoplay loop />
      </LoadingSize>
    </Loading>
  );
};

export default Loader;
