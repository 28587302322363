import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ButtonLogin,
  ButtonSignup,
  Buttons,
  HeaderContainer,
  StyledDiv,
  Logo,
  FullNameText,
  LogoutContainer,
  StyledText,
  MenuContainer,
  Test,
} from "./Header.style";
import SmallScreensSideBarModal from "../Modal/SmallScreensSideBarModal";
import LocaleSelection from "../LocaleSelection/LocaleSelection";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { selectedLocale } from "../../store/slices/common/slice";
import { useSelector } from "react-redux";
import Icon from "../utils/Icon";
import { FRONT_URL } from "../../api";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSidebarModal, setOpenSidebarModal] = useState(false);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  const { pathname } = useLocation();

  const loggedIn = false;
  const fullName = "Srdjan Uzunovic";

  const handleLogout = () => {
    // if (businessUserInfo.id > 0) {
    //   dispatch(logout())
    //     .unwrap()
    //     .then(() => {
    //       close();
    //       navigate("/login");
    //     })
    //     .catch((error) => {
    //       openToast(toastId, "logout-sidebar", "error", t(error.message));
    //     });
    // } else if (userInfo.id > 0) {
    //   dispatch(logoutRecipient())
    //     .unwrap()
    //     .then(() => {
    //       close();
    //       navigate("/login/user");
    //     })
    //     .catch((error) => {
    //       openToast(toastId, "logout-sidebar", "error", t(error.message));
    //     });
    // }
  };

  return (
    <HeaderContainer>
      <StyledDiv
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo />
        {FRONT_URL !== "https://csr.ygii.app" && <Test>TEST</Test>}
      </StyledDiv>
      <MenuContainer $ar={ar}>
        <Menu
          onClick={() => {
            setOpenSidebarModal(!openSidebarModal);
          }}
        />
      </MenuContainer>
      <Buttons>
        <LocaleSelection color />
        {loggedIn ? (
          <Buttons $gap>
            <FullNameText onClick={() => navigate("/dashboard")} $ar={ar}>
              {t("hello")}, {t(`${fullName}`)}
            </FullNameText>
            {pathname !== "/dashboard" && (
              <>
                <LogoutContainer>
                  <StyledText onClick={handleLogout}>{t("logout")}</StyledText>
                  <Icon icon={<LogoutIcon />} selectedLocale={ar} />
                </LogoutContainer>
                <ButtonSignup onClick={() => navigate("/")}>
                  <Icon
                    icon={<DashboardIcon style={{ marginBottom: -3 }} />}
                    selectedLocale={ar}
                  />
                </ButtonSignup>
              </>
            )}
          </Buttons>
        ) : (
          pathname !== "/dashboard" && (
            <Buttons>
              <ButtonLogin
                onClick={() => {
                  //   navigate("/log-in");
                }}
              >
                {t("log-in")}
              </ButtonLogin>
              <ButtonSignup
                onClick={() => {
                  navigate("/sign-up");
                }}
              >
                {t("sign-up")}
              </ButtonSignup>
            </Buttons>
          )
        )}
      </Buttons>
      <SmallScreensSideBarModal
        ar={ar}
        open={openSidebarModal}
        close={() => {
          setOpenSidebarModal(false);
        }}
        setOpenSidebarModal={setOpenSidebarModal}
        openSidebarModal={openSidebarModal}
        loggedIn={loggedIn}
      />
    </HeaderContainer>
  );
};

export default Header;
