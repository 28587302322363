import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { ReactComponent as Logo } from "./assets/icons/Logo2.svg";
import { ReactComponent as CloseSvg } from "./assets/icons/close.svg";

export const LogoIcon = styled(Logo)`
  margin-top: 2px;
  margin-right: 2.5px;
  height: 20px;
  opacity: 0.75;
  width: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.25));
`;
export const CloseContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;
export const Close = styled(CloseSvg)`
  width: auto;
  height: 18px;
  margin-left: ${(props) => (props.$ar ? "-12px" : "-0px")};
  margin-right: ${(props) => (props.$ar ? "0px" : "-12px")};
  opacity: 0.75;
  margin-top: -2px;
  fill: #fff;

  &:hover {
    fill: #f73e50;
  }
`;
export const SplashContainer = styled.div`
  height: 100dvh;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__progress-bar {
    border-radius: 0px 20px 20px 0px;
  }

  .Toastify__toast--success {
    /* background: #04a65b; */
    background: linear-gradient(220deg, #82d3ad 0%, #04a65b 100%);
  }
  .Toastify__progress-bar--success {
    background-color: #82d3ad;
  }

  .Toastify__toast--error {
    background: linear-gradient(220deg, #fb9fa8 0%, #f73e50 100%);

    /* background: #f73e50; */
  }
  .Toastify__progress-bar--error {
    background-color: #fb9fa8;
  }

  .Toastify__toast--warning {
    /* background: #f88f00; */
    background: linear-gradient(220deg, #fcc780 0%, #f88f00 100%);
  }
  .Toastify__progress-bar--warning {
    background-color: #fcc780;
  }

  .Toastify__toast--info {
    /* background: #3498db; */
    background: linear-gradient(220deg, #b9cdf2 0%, #3498db 100%);
  }
  .Toastify__progress-bar--info {
    background-color: #b9cdf2;
  }

  .Toastify__toast {
    overflow: visible;
    border-radius: 10px 10px 10px 0px;
  }

  .Toastify__toast-body {
    position: relative;
    color: white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    font-family: "Avenir-Medium";
    font-size: 14px;
  }

  .Toastify__close-button {
    color: #fff;
    opacity: 0.5;
  }
  .Toastify__close-button:hover {
    color: #f73e50;
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: ${(props) => (props.$ar ? "unset" : "-30px")};
  right: ${(props) => (props.$ar ? "-30px" : "unset")};
  top: 50%;
  transform: translateY(-50%);
  min-width: 38px;
  min-height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: ${({ type }) => {
    switch (type) {
      case "success":
        return "0px 0px 9px 3px rgba(0,94,57, 0.3);";
      case "error":
        return "0px 0px 9px 3px rgba(124,31,40, .3);";
      case "warning":
        return "0px 0px 9px 3px rgba(124,72,0, 0.3);";
      case "info":
        return "0px 0px 9px 3px rgba(26, 76, 110, 0.3)";
      default:
        return "transparent";
    }
  }};

  background-color: ${({ type }) => {
    switch (type) {
      case "success":
        return "#005e39";
      case "error":
        return "#7c1f28";
      case "warning":
        return "#7c4800";
      case "info":
        return "#1a4c6e";
      default:
        return "transparent";
    }
  }};
`;
