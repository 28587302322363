import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { signUpOrganization } from "../../../store/slices/auth/asyncThunks";
import Header from "../../../components/Header/Header";

import {
  ButtonContainer,
  SignupButton,
  ScreenContent,
  MainContainer,
  Title,
  CreateText,
  Message,
  SuccessImg,
  HeaderWrapper,
  FormDiv,
  TitleWrapper,
  StyledTextDiv,
} from "./SignupPage.style";
import { selectCreateAccountPending } from "../../../store/slices/auth/slice";
import { selectedLocale } from "../../../store/slices/common/slice";
import Loader from "../../../components/Loader/Loader";
import Success from "../../../assets/images/success.png";
import { ReactComponent as Loading } from "../../../assets/icons/loader-white.svg";
import SignUpFormComponent from "./SignUpFormComponent";
import openToast from "../../../utils/toast";
import Footer from "../../../components/Footer/Footer";
import { isValidEmail, isValidPassword } from "../../../utils/utils";
import { Bounce } from "react-reveal";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";

const SignupPage = () => {
  const { t } = useTranslation();
  const [organizationName, setOrganizationName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);
  const dispatch = useDispatch();
  const continueLoading = useSelector(selectCreateAccountPending);
  const [tab, setTab] = useState(0);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  const toastId = useRef(null);

  const [checkValidation, setCheckValidation] = useState(false);

  const validateForm = () => {
    let formIsValid = true;

    if (!organizationName) {
      openToast(
        toastId,
        "sign-up",
        "error",
        t("organization-name-is-required")
      );
      formIsValid = false;
      return;
    }

    if (!firstName) {
      openToast(toastId, "sign-up", "error", t("first-name-is-required"));
      formIsValid = false;
      return;
    }

    if (!lastName) {
      openToast(toastId, "sign-up", "error", t("last-name-is-required"));
      formIsValid = false;
      return;
    }

    if (!phoneNumber) {
      openToast(toastId, "sign-up", "error", t("phone-number-is-required"));
      formIsValid = false;
      return;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      openToast(toastId, "sign-up", "error", t("invalid-phone-number"));

      formIsValid = false;
      return;
    }
    if (!email) {
      openToast(toastId, "sign-up", "error", t("email-is-required"));
      formIsValid = false;
      return;
    } else if (!isValidEmail(email)) {
      openToast(toastId, "sign-up", "error", t("invalid-email-format"));

      formIsValid = false;
      return;
    }

    if (!password) {
      openToast(toastId, "sign-up", "error", t("password-is-required"));
      formIsValid = false;
      return;
    } else if (!isValidPassword(password)) {
      openToast(toastId, "sign-up", "info", t("password-contain"));
      formIsValid = false;
      return;
    }

    if (!confirmPassword) {
      openToast(toastId, "sign-up", "error", t("confirm-password-is-required"));
      formIsValid = false;
      return;
    } else if (confirmPassword !== password) {
      openToast(toastId, "sign-up", "error", t("password-dont-match"));
      formIsValid = false;
      return;
    }

    return formIsValid;
  };

  const handleSignup = () => {
    setCheckValidation(true);
    if (validateForm()) {
      dispatch(
        signUpOrganization({
          email,
          firstName,
          lastName,
          password,
          name: organizationName,
          phoneNumber: phoneNumber?.replaceAll(" ", ""),
        })
      )
        .unwrap()
        .then((response) => {
          toast.success(t("organization-has-been-created-successfully"));
          setTab(1);
        })
        .catch((error) => {
          openToast(toastId, "sign-up", "error", t(error.message));
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    window.scrollTo(0, 0);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <MainContainer>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        {tab === 0 && (
          <Bounce>
            <ScreenContent>
              <TitleWrapper>
                <Title>{t("welcome-to-ygii")}</Title>
                <StyledTextDiv>
                  <CreateText>
                    {t("already-have-an-account")}
                    <Link to="/sign-up" className="signup">
                      {t("log-in")}
                    </Link>
                  </CreateText>

                  <CreateText>{t("submit-your-request")}</CreateText>
                </StyledTextDiv>
              </TitleWrapper>

              <SignUpFormComponent
                ar={ar}
                checkValidation={checkValidation}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                setFirstName={setFirstName}
                firstName={firstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                visibility={visibility}
                visibility2={visibility2}
                confirmPassword={confirmPassword}
                password={password}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                setVisibility={setVisibility}
                setVisibility2={setVisibility2}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />

              {!continueLoading ? (
                <ButtonContainer>
                  <SignupButton onClick={handleSignup}>
                    {t("submit")}
                  </SignupButton>
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <SignupButton disabled>
                    <Loading height={30} />
                  </SignupButton>
                </ButtonContainer>
              )}
            </ScreenContent>
          </Bounce>
        )}
        {tab === 1 && (
          <FormDiv>
            <SuccessImg src={Success} alt="success-img" />
            <Message $bold>
              {t("we-have-received-your-request-successfully")}
            </Message>
            <Message>{t("our-team-will-contact-you-shortly")}</Message>
          </FormDiv>
        )}
      </MainContainer>
      {continueLoading && <Loader />}
      <Footer ar={ar} />
    </>
  );
};

export default SignupPage;
