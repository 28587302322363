import { signUpOrganization } from "./asyncThunks";

export const signUpOrganizationBuilder = (builder) => {
  builder.addCase(signUpOrganization.pending, (state) => {
    state.createAccountPending = true;
  });
  builder.addCase(signUpOrganization.fulfilled, (state, { payload }) => {
    const { organization } = payload;
    state.createAccountPending = false;
    state.loggedIn = true;
    state.organization = organization;
  });
  builder.addCase(signUpOrganization.rejected, (state) => {
    state.createAccountPending = false;
  });
};
