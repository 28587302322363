import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoGift } from "../../assets/icons/emptyGift.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

export const SideBar = styled.div`
  position: fixed;
  padding: 72px 22px 22px 22px;
  margin-top: ${(props) =>
    props.$upgradePackage ? "calc(6.9rem + 6rem)" : "75px"};
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 0px 10px 5px rgba(81, 81, 145, 0.05);
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  z-index: 10;
  user-select: none;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.$ar ? "unset" : 0)};
  right: ${(props) => (props.$ar ? 0 : "unset")};
  @media (max-height: 1200px) {
    margin-top: ${(props) =>
      props.$upgradePackage ? "calc(6.9rem + 6rem)" : "68px"};
    padding: ${(props) =>
      props.$height ? "68px 22px 22px 22px" : "32px 22px 22px 22px"};
  }

  @media (max-width: 870px) {
    display: none;
  }
`;
export const LogoIcon = styled(Logo)`
  path {
    fill: #85a1ac;
  }
`;
export const LogoGiftIcon = styled(LogoGift)`
  height: auto;
  @media (max-height: 985px) {
    width: 40px;
  }
`;

export const LogoutIcon = styled(Logout)`
  height: auto;
  width: 27px;
  @media (max-height: 985px) {
    width: 25px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (max-height: 825px) {
    overflow-x: auto;
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(62dvh - 46px)" : "62dvh"};
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c8c8db;
      border-radius: 10px;
    }
  }
  @media (max-height: 695px) {
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(50dvh - 46px)" : "50dvh"};
  }
  @media (max-height: 640px) {
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(45dvh - 46px)" : "45dvh"};
  }
  @media (max-height: 565px) {
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(40dvh - 46px)" : "40dvh"};
  }
  @media (max-height: 515px) {
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(30dvh - 46px)" : "30dvh"};
  }
  @media (max-height: 448px) {
    max-height: ${(props) =>
      props.$categoriesBar ? "calc(20dvh - 46px)" : "20dvh"};
  }
`;
export const NavDiv = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
  @media (max-height: 985px) {
    gap: 25px;
  }
`;

export const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 985px) {
    width: 25px;
    height: 25px;
  }
`;

export const LogoText = styled.span`
  font-family: "Avenir-Light";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.177777px;
  margin-left: -12px;
  color: #454584;
`;
export const Relative = styled.div`
  position: relative;
  @media (max-height: 1200px) {
    display: none;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-height: 1200px) {
    display: none;
  }
`;

export const AdminInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  @media (max-height: 1130px) {
    margin-bottom: 50px;
  }
  @media (max-height: 985px) {
    margin-bottom: 25px;
  }
`;

export const PhotoElipse = styled.div`
  width: 101px;
  height: 101px;
  display: none;
  border-radius: 50%;
`;

export const AdminName = styled.span`
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.266666px;
  text-transform: uppercase;
  color: #222239;
  max-width: 20rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  @media (max-width: 870px) {
    max-width: none;
  }
  @media (max-height: 985px) {
    font-size: 18px;
  }
`;

export const AdminAccount = styled.span`
  user-select: none;
  font-family: "Avenir-Light";
  font-style: normal;

  font-size: 16px;

  letter-spacing: -0.177777px;

  color: #454584;

  opacity: 0.6;
  @media (max-height: 985px) {
    font-size: 14px;
  }
`;

export const Row = styled.div`
  cursor: pointer;
  width: fit-content;
  align-items: center;
  gap: 15px;
  display: flex;
  flex-direction: row;
`;
export const NavTitle = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 400;
  user-select: none;
  font-size: 18px;
  letter-spacing: -0.18px;
  color: #fff;
`;
export const ProfilePhoto = styled.img`
  min-width: 101px;

  max-width: 170px;
  max-height: 101px;
  margin-bottom: 10px;
  border-radius: 50%;

  @media (max-height: 1200px) {
    max-height: 80px;
    min-width: auto;
  }
`;
export const ProfilePicEmpty = styled.div`
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-height: 985px) {
    width: 80px;
    height: 80px;
  }
`;

export const StyledBar = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 75px;
  width: 100%;
  @media (max-height: 1130px) {
    margin-top: 35px;
  }
  @media (max-height: 985px) {
    margin-top: 15px;
    margin-bottom: 18px;
  }
`;

export const GiftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: #000;
  font-family: "Avenir-Medium";
  font-size: 14px;
  letter-spacing: 0.067px;
`;

export const UpgradeButton = styled.button`
  border-radius: 5px;
  cursor: pointer;
  background: #484888;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-family: "Avenir";
  gap: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.067px;
  border: none;
  @media (max-width: 825px) {
    font-size: 13px;
  }
`;

export const LogoutButton = styled(GiftContainer)`
  color: #85a1ac;
  transition: all 0.2s ease-in-out;

  &:hover {
    padding-left: 10px;
  }
`;

export const StyledNavLink = styled(NavLink)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6;
  text-decoration: none;
  color: #85a1ac;
  font-family: "Avenir-Medium";
  gap: 15px;
  font-size: 14px;
  letter-spacing: 0.0666667px;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  width: 100%;
  border-radius: 10px;

  &.active {
    opacity: 1;
    padding-left: 20px;
  }

  &:hover {
    padding-left: 20px;
    opacity: 1;
    background-color: #85a1ac22;
  }

  @media (max-height: 695px) {
    padding: 5px;
    padding-left: 10px;
  }
`;

export const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  color: #000;
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const TestEnvContainer = styled.div`
  display: flex;
  justify-content: center;
`;
