import React from "react";
import { useTranslation } from "react-i18next";
import AppStore from "../../assets/images/appstore.png";
import GooglePlay from "../../assets/images/googleplay.png";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Instagram from "../../assets/images/instagram.png";
import Twitter from "../../assets/images/x.png";
import Linkedin from "../../assets/images/linkedin.png";
import TikTok from "../../assets/images/tiktok.png";
import Copy from "../../assets/images/copy.png";

import {
  Footer,
  TitleDownload,
  Download,
  FooterContainer,
  StoreContainer,
  TextFooter,
  FooterLeft,
  FooterColumn,
  ColumTitle,
  ColumnLink,
  FooterRow,
  Media,
  MediaContainer,
  CopyContainer,
  CopyText,
  Bg,
  Row,
  ColumnLinkHref,
  StyledDiv,
  Image,
  StoreImage,
} from "./Footer.styles";
import { Bounce } from "react-reveal";

const FooterComponent = ({ ar }) => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Bg>
        <Download>
          <TitleDownload>{t("download-the-app")}</TitleDownload>

          <StoreContainer $ar={ar}>
            <a
              href="https://apps.apple.com/vn/app/ygii-gifts-wishlists/id1609129551"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreImage
                src={AppStore}
                alt="App Store"
                className="store"
                $borderRadius
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ygii&hl=en_US&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreImage
                src={GooglePlay}
                alt="img"
                className="store"
                $borderRadius
              />
            </a>
          </StoreContainer>
        </Download>
      </Bg>
      <StyledDiv>
        <Footer>
          <Row>
            <FooterLeft>
              <Logo />
              <TextFooter>{t("unlock-power")}</TextFooter>
            </FooterLeft>
            <FooterRow>
              <FooterColumn>
                <ColumTitle>{t("company")}</ColumTitle>

                <ColumnLink
                  onClick={() => {
                    window.open(
                      "https://portal.ygii.app/en/about-us",
                      "_blank"
                    );
                  }}
                >
                  {t("about-us")}
                </ColumnLink>

                <ColumnLink
                  onClick={() => {
                    window.open("https://portal.ygii.app/en/pricing", "_blank");
                  }}
                >
                  {t("pricing")}
                </ColumnLink>

                <ColumnLink
                  onClick={() => {
                    window.open(
                      "https://ygii-s3-bucket.s3.me-south-1.amazonaws.com/Help.pdf",
                      "_blank"
                    );
                  }}
                >
                  {t("help-support")}
                </ColumnLink>
              </FooterColumn>
              <FooterColumn>
                <ColumTitle>{t("products")}</ColumTitle>
                <ColumnLink
                  onClick={() => {
                    window.open(
                      "https://portal.ygii.app/en/marketplace",
                      "_blank"
                    );
                  }}
                >
                  {t("marketplace")}
                </ColumnLink>
                <ColumnLink
                  onClick={() => {
                    window.open("https://portal.ygii.app/en/pricing", "_blank");
                  }}
                >
                  {t("gifting-as")}
                </ColumnLink>
                <ColumnLink
                  onClick={() => {
                    window.open("https://www.ygii.app/", "_blank");
                  }}
                >
                  {t("user-app")}
                </ColumnLink>
              </FooterColumn>
              <FooterColumn>
                <ColumTitle>{t("contact")}</ColumTitle>
                <ColumnLink
                  onClick={() => {
                    window.open("https://calendly.com/abdulkareem-2", "_blank");
                  }}
                >
                  {t("request-demo")}
                </ColumnLink>

                <ColumnLinkHref href={"mailto:hello@ygii.app"}>
                  {t("contact-us")}
                </ColumnLinkHref>
                <ColumnLink
                  onClick={() =>
                    window.open(
                      "https://portal.ygii.app/en/privacy-policy",
                      "_blank"
                    )
                  }
                >
                  {t("privacy-policy")}
                </ColumnLink>
              </FooterColumn>
            </FooterRow>
          </Row>
          <div>
            <MediaContainer>
              <Media>
                <a
                  href="https://www.instagram.com/ygiiapp/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Instagram} alt="instagram" />
                </a>
                <a
                  href="https://twitter.com/ygiiapp?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Twitter} alt="twitter" />
                </a>
                <a
                  href="https://www.tiktok.com/@ygiiapp?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={TikTok} alt="tiktok" />
                </a>
                <a
                  href="https://www.linkedin.com/company/ygiiapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Linkedin} alt="linkedin" />
                </a>
              </Media>
            </MediaContainer>
            <CopyContainer>
              <Image src={Copy} alt="copy" />
              <CopyText>{t("copy-text")}</CopyText>
            </CopyContainer>
          </div>
        </Footer>
      </StyledDiv>
    </FooterContainer>
  );
};

export default FooterComponent;
