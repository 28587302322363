import { styled } from "styled-components";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as CompanyIcon } from "../../../assets/icons/group.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import { ReactComponent as Visibility } from "../../../assets/icons/visibility.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";

export const Error = styled.div`
  color: #dc143c;
  font-size: 13px;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
`;
export const SuccessImg = styled.img`
  background-color: transparent;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 150px;
  align-self: center;
  height: 150px;
`;
export const Message = styled.span`
  color: #85a1ac;
  text-align: center;
  font-family: ${(props) => (props.$bold ? "FrankfurterEF" : "Avenir-Light")};
  font-size: ${(props) => (props.$bold ? "40px" : "30px")};
  letter-spacing: -0.178px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MainContainer = styled.div`
  flex-direction: column;
  background: #fff;
  align-items: center;
  gap: 150px;
  min-height: 100vh;
  display: flex;
  z-index: 0;
  position: relative;
`;
export const HeaderWrapper = styled.header`
  background: #85a1ac;
  z-index: 500;
  position: fixed;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ScreenContent = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 180px;
  flex-direction: column;
  background-color: #fff;
  min-height: 520px;
  padding: 30px 10px 60px 10px;
  border: 1px solid #b1b1b1;
  width: 548px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  -moz-box-shadow: 0px 0px 15px 5px rgba(1, 1, 1, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(1, 0, 1, 0.1);
  border-radius: 30px;

  @media (max-width: 620px) {
    width: 90%;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;
export const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
export const StyledTextDiv = styled(TextDiv)`
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const CreateText = styled.span`
  color: #85a1ac;
  opacity: 0.7;
  font-size: 16px;
  font-family: "Avenir";
  user-select: none;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 250px;
`;
export const Form = styled.div`
  display: flex;
  padding: 30px 40px 60px 40px;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 30px;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;
export const SignupField = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  padding: 20px 60px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const Input = styled.input`
  border: ${(props) =>
    props.$error ? "1px solid #FF9494" : "1px solid #8c8c8c"};
  border-radius: 15px;
  height: 60px;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;
  outline: none;
  color: #85a1ac;
  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    color: #000;
    &::placeholder {
      color: #000;
    }
  }

  &::placeholder {
    color: #85a1ac;
  }

  padding: ${(props) =>
    props.$ar ? "15px 38px 15px 15px" : "15px 15px 15px 38px"};
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.178px;

  @media (max-width: 450px) {
    font-size: 14px;
    border-radius: 7.5px;
    height: 39px;
  }
`;
export const StyledInput = styled.input`
  border: ${(props) =>
    props.$error ? "1px solid #FF9494" : "1px solid #8c8c8c"};
  border-radius: 15px;
  height: 60px;
  user-select: none;
  background: none;
  width: 100%;
  font-weight: 700;
  color: #85a1ac;
  outline: none;
  transition: 0.2s;
  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    color: #000;
    &::placeholder {
      color: #000;
    }
  }

  &::placeholder {
    color: #85a1ac;
  }
  padding: ${(props) =>
    props.$ar ? "15px 38px 15px 15px" : "15px 15px 15px 38px"};

  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.178px;

  @media (max-width: 450px) {
    font-size: 14px;
    border-radius: 7.5px;
    height: 39px;
  }
`;

export const LastNameInput = styled(StyledInput)`
  padding: 15px;
`;

export const SignupButton = styled.button`
  font-family: "FrankfurterEF";
  letter-spacing: -0.178px;
  transition: transform 0.3s ease;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: white;
  background-color: #85a1ac;
  box-shadow: 0px 12px 24px -6px #85a1ac33;
  border-radius: 15px;
  padding: 18px 35px;
  border: none;
  width: 100%;
  height: 70px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background: ${({ disabled }) => (disabled ? null : "#85a1ac")};
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? null
        : `
    box-shadow: 0px 2px 8px -5px rgb(128, 128, 128);
    transition: all 0.1s ease-in;
    transform: scale(0.95);
  `};
  }
  @media (max-width: 450px) {
    font-size: 16px;
    border-radius: 7.5px;
    height: 45px;
  }
`;

export const Title = styled.h1`
  justify-content: center;
  display: flex;
  text-align: center;
  user-select: none;
  flex-wrap: wrap;
  font-family: "FrankfurterEF", sans-serif;
  font-size: 48px;
  line-height: 55px;
  letter-spacing: -0.177777px;
  color: #85a1ac;
  @media (max-width: 450px) {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
`;
export const Subtitle = styled.h2`
  display: flex;
  justify-content: center;
  color: #85a1ac;
  user-select: none;
  opacity: 0.7;
  font-family: "Avenir-Heavy";
  font-size: 14px;
  text-transform: uppercase;
`;

export const Center = styled.div`
  margin-top: -25px;
  display: flex;
  justify-content: center;
`;

export const CreateAccText = styled.span`
  color: #85a1ac;
  opacity: 0.7;
  font-family: "Avenir-Heavy";
  font-size: 14px;
  margin-top: 25px;
`;

export const PrivacyText = styled.span`
  text-align: center;

  font-size: 11px;
  color: #6a679e;
  margin-top: 34px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const StyledUserIcon = styled(UserIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17.5px;

  right: ${(props) => (props.$ar ? "10px" : "unset")};
  left: ${(props) => (props.$ar ? "unset" : "10px")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;

export const StyledCompanyIcon = styled(CompanyIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17.5px;
  right: ${(props) => (props.$ar ? "10px" : "unset")};
  left: ${(props) => (props.$ar ? "unset" : "10px")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;

export const StyledLockIcon = styled(LockIcon)`
  width: 24px;
  height: 24px;
  position: absolute;

  top: 17.5px;
  right: ${(props) => (props.$ar ? "10px" : "unset")};
  left: ${(props) => (props.$ar ? "unset" : "10px")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;

export const StyledVisibilityOff = styled(VisibilityOff)`
  width: 24px;
  height: 24px;

  cursor: pointer;
  position: absolute;
  top: 17.5px;
  right: ${(props) => (props.$ar ? "unset" : "20px")};
  left: ${(props) => (props.$ar ? "20px" : "unset")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;

export const StyledVisibility = styled(Visibility)`
  width: 24px;
  height: 24px;

  cursor: pointer;
  position: absolute;
  top: 17.5px;
  right: ${(props) => (props.$ar ? "unset" : "20px")};
  left: ${(props) => (props.$ar ? "20px" : "unset")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;

export const StyledMailIcon = styled(MailIcon)`
  width: 24px;
  height: 24px;

  position: absolute;
  top: 17.5px;
  right: ${(props) => (props.$ar ? "10px" : "unset")};
  left: ${(props) => (props.$ar ? "unset" : "10px")};
  @media (max-width: 450px) {
    top: 6.5px;
  }
`;
