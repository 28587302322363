import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SplashScreen from "./components/Splash/Splash";
import HomePage from "./screens/HomePage/HomePage";
import { selectedLocale } from "./store/slices/common/slice";
import SignupPage from "./screens/OnboardingPages/SignupPage/SignupPage";
import { ReactComponent as SuccessIcon } from "./assets/icons/success.svg";
import { ReactComponent as ErrorIcon } from "./assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "./assets/icons/info.svg";
import { ReactComponent as WarningIcon } from "./assets/icons/warning.svg";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "./utils/constants";
// import { selectLoggedIn, setLoggedIn } from "./store/slice/auth/slice";
import {
  Close,
  CloseContainer,
  IconContainer,
  LogoIcon,
  SplashContainer,
  StyledToastContainer,
} from "./App.styles";
import LoginPage from "./screens/OnboardingPages/LoginPage/LoginPage";
import ForgotPasswordPage from "./screens/OnboardingPages/ForgotPasswordPage/ForgotPasswordPage";
import SetUpNewPasswordPage from "./screens/OnboardingPages/SetUpNewPasswordPage/SetUpNewPasswordPage";
import DashboardPage from "./screens/DashboardPage/DashboardPage";

function App() {
  const dispatch = useDispatch();
  // const isAuthenticated = useSelector(selectLoggedIn);
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
  //   if (token) {
  //     dispatch(fetchUserInfo());
  //   } else {
  //     dispatch(setLoggedIn({ loggedIn: false }));
  //   }
  // }, [dispatch]);

  document.documentElement.lang = locale;
  useEffect(() => {
    if (locale === "ar") {
      document.body.style.direction = "rtl";
    } else {
      document.body.style.direction = "ltr";
    }
  }, [locale]);

  return (
    <Fragment>
      {
        // isAuthenticated !== null &&
        minimumDurationPassed ? (
          <>
            <StyledToastContainer
              // position={locale !== "en" ? "top-left" : "top-right"}
              position={"top-right"}
              autoClose={6000}
              hideProgressBar={false}
              rtl={locale !== "en"}
              newestOnTop
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              closeButton={({ closeToast }) => (
                <CloseContainer>
                  <Close $ar={ar} />
                  <LogoIcon onClick={closeToast} />
                </CloseContainer>
              )}
              icon={({ closeToast, type }) => (
                <IconContainer type={type} $ar={ar}>
                  {type === "success" && <SuccessIcon onClick={closeToast} />}
                  {type === "error" && <ErrorIcon onClick={closeToast} />}
                  {type === "warning" && <WarningIcon onClick={closeToast} />}
                  {type === "info" && <InfoIcon onClick={closeToast} />}
                </IconContainer>
              )}
            />

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/sign-up" element={<SignupPage />} />
              {/* <Route path="/log-in" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/set-up-new-password"
                element={<SetUpNewPasswordPage />}
              />
              <Route path="/dashboard" element={<DashboardPage />} /> */}
            </Routes>
          </>
        ) : (
          <SplashContainer>
            <SplashScreen />
          </SplashContainer>
        )
      }
    </Fragment>
  );
}

export default App;
