import { styled } from "styled-components";

export const Loading = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1.3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const LoadingSize = styled.div`
  width: 350px;
`;
