import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { PhoneInputWrapper } from "./PhoneNumberInput.style";
import {
  selectedLocale,
  setCountryCode,
} from "../../store/slices/common/slice";
import { LOCAL_STORAGE_PHONE_COUNTRY } from "../../utils/constants";
import { isValidPhoneNumber } from "libphonenumber-js";

const PhoneNumberInput = ({
  phone,
  setPhone,
  placeholder,
  autoFocus,
  defaultCode,
  disabled,
  checkValidation,
}) => {
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const countryCode = useSelector((state) => state.commonInfo.countryCode);
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";
  return (
    <PhoneInputWrapper
      $ar={ar}
      $focused={focused}
      $error={checkValidation && !isValidPhoneNumber(phone)}
    >
      <PhoneInput
        searchStyle={{
          borderRadius: 15,
          color: "#85a1ac",
          padding: "5px 10px",
        }}
        inputStyle={{ borderRadius: 15, height: 60 }}
        placeholder={placeholder}
        enableSearch
        defaultCountry={defaultCode || countryCode}
        disableSearchIcon
        countryCodeEditable={false}
        specialLabel={false}
        country={"sa"}
        value={phone}
        onChange={(value, _, e, formattedValue) => {
          if (value === "3810") {
            return;
          }
          setPhone(formattedValue);
        }}
        inputProps={{
          autoFocus: autoFocus,
          disabled: disabled,
          onFocus: () => setFocused(true),
          onBlur: () => {
            setFocused(false);
            if (phone.startsWith("0")) {
              setPhone(phone.substring(1));
            }
          },
        }}
        countrySelectProps={{
          onBlur: () => setFocused(false),
          onFocus: () => setFocused(true),
          onChange: (country) => {
            localStorage.setItem(LOCAL_STORAGE_PHONE_COUNTRY, country.cca2);
            dispatch(setCountryCode(country.cca2));
          },
        }}
        masks={{ rs: ".. ... .. .." }}
      />
    </PhoneInputWrapper>
  );
};

export default PhoneNumberInput;
