import axiosApiClient from "..";

import { handleError } from "../../utils/errors";

const BASE_URL = "common";

class CommonServices {}

const commonServices = new CommonServices();

export default commonServices;
