import styled from "styled-components";

export const PhoneInputWrapper = styled.div`
  width: 100%;

  .react-tel-input {
    font-family: "Avenir-Medium", sans-serif;
  }

  .flag-dropdown {
    width: 5.2rem;
    &.open {
      width: 100%;
    }
  }

  .selected-flag {
    padding: ${(props) =>
      props.$ar
        ? "0px 11px 0px 0px !important"
        : "0px 0px 0px 11px !important"};

    &.open {
      width: 100%;
    }
  }
  .react-tel-input .country-list .search {
    padding: 10px 10px 10px 6px;
  }
  .search-box {
    width: 100% !important;
    padding: 8px !important;
    border-radius: 10px !important;
  }
  .arrow {
    right: ${(props) => (props.$ar ? "29px !important" : "unset !important")};
    left: ${(props) => (props.$ar ? "unset !important" : "29px !important")};
  }

  .country-list {
    width: 330px !important;
    background-color: white;
    color: #85a1ac;
    /* border: 2px solid #d1d1d4; */
    width: unset;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .dial-code {
    font-weight: bold;
    color: #85a1ac !important;
  }

  .country {
    &.highlight {
      background-color: #6a678e33 !important;
    }
  }

  .form-control {
    border: ${(props) =>
      props.$error ? "1px solid #FF9494" : "1px solid #8c8c8c"} !important;
    border-radius: 15px !important;
    color: ${(props) => (props.$focused ? "#000" : "#85a1ac")} !important;
    font-family: "Avenir" !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    letter-spacing: -0.178px;
    background: none !important;
    width: 100% !important;
    padding-left: ${(props) => (props.$ar ? "unset" : "54px")};
    padding-right: ${(props) => (props.$ar ? "54px" : "unset")};
    text-align: ${(props) => (props.$ar ? "right" : "left")};

    height: 60px !important;

    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      color: #000 !important;
      &::placeholder {
        color: #000 !important;
      }
    }

    &::placeholder {
      color: #85a1ac;
    }

    @media (max-width: 450px) {
      font-size: 14px !important;
      border-radius: 7.5px !important;
      height: 30px !important;
    }
  }
`;
