import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/responsive.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n/I18n";
import { Chart, ArcElement } from "chart.js";
import { FRONT_URL, injectStore } from "./api/index";

import { useSelector } from "react-redux";
import { selectedLocale, setSelectedLocale } from "./store/slices/common/slice";
import { SESSION_STORAGE_SELECTED_LOCALE } from "./utils/constants";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import "react-toastify/dist/ReactToastify.css";

injectStore(store);
Chart.register(ArcElement);

const AppWithRouter = () => {
  const locale = useSelector(selectedLocale);
  const { pathname, search } = window.location;
  const dispatch = useDispatch();

  const fullPath = window.location.href;
  let languageInPath = fullPath?.split(FRONT_URL)[1]?.split("/")[1];

  if (!["en", "ar"].includes(languageInPath)) {
    languageInPath = null;
  }

  const language = languageInPath ? languageInPath : locale ?? "ar";

  useEffect(() => {
    if (!pathname.startsWith(`/${language}`)) {
      const newPath = search
        ? `/${language}${pathname
            .replaceAll("/ar", "")
            .replaceAll("/en", "")}${search}`
        : `/${language}${pathname.replaceAll("/ar", "").replaceAll("/en", "")}`;

      window.history.replaceState({}, "", newPath);
      window.location.reload();
    }
    if (language !== locale) {
      localStorage.setItem(SESSION_STORAGE_SELECTED_LOCALE, language);
      dispatch(setSelectedLocale(language));
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <Router basename={`/${language}`}>
      <App />
    </Router>
  );
};

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AppWithRouter />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
