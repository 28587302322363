import {
  Button,
  ButtonContainer,
  HomePageContainer,
  H1,
  LeftSide,
  Text,
  ButtonStroke,
  SectionTwo,
  Sponsors,
  Text2,
  HeaderWrapper,
  RightSide,
  Wrapper,
  TitleWrapper,
  RotateGiftIcon,
  HomeContainer,
  StyledImage,
  SectionTwoContainer,
  SponsorImgFirst,
  SponsorImg,
  Gift,
  Row,
  StyledH1,
} from "./HomePage.styles.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Bounce, Slide } from "react-reveal";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import PexelsKarolinaGrabowska from "../../assets/images/heroImage.jpeg";
import PexelsKarolinaGrabowskaLow from "../../assets/images/heroImageLow.jpg";
import Partenr1 from "../../assets/images/partner1.png";
import Partner2 from "../../assets/images/partner2.png";
import Partner3 from "../../assets/images/partner3.png";
import { selectedLocale } from "../../store/slices/common/slice.js";
import Icon from "../../components/utils/Icon.js";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useSelector(selectedLocale);
  const ar = locale === "ar";

  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <HomeContainer>
        <HomePageContainer>
          <Wrapper>
            <Bounce right={ar} left={!ar}>
              <LeftSide>
                <TitleWrapper>
                  {ar ? (
                    <Row>
                      <StyledH1>{t("responsibility")}</StyledH1>
                      <RotateGiftIcon $ar={ar}>
                        <Icon icon={<Gift />} selectedLocale={ar} />
                      </RotateGiftIcon>
                    </Row>
                  ) : (
                    <>
                      <H1>{t("corporate-social")}</H1>
                      <Row>
                        <StyledH1>{t("responsibility")}</StyledH1>
                        <RotateGiftIcon $ar={ar}>
                          <Icon icon={<Gift />} selectedLocale={ar} />
                        </RotateGiftIcon>
                      </Row>
                    </>
                  )}

                  <Text>
                    {t(
                      "send-personalized-gifts-create-a-mile-and-make-wishes-come-true"
                    )}
                  </Text>
                </TitleWrapper>

                <ButtonContainer>
                  <Button
                    onClick={() => {
                      navigate("/sign-up");
                    }}
                  >
                    {t("add-organization")}
                  </Button>
                  <ButtonStroke
                    onClick={() => {
                      window.open(
                        "https://calendly.com/abdulkareem-2",
                        "_blank"
                      );
                    }}
                  >
                    {t("lets-talk")}
                  </ButtonStroke>
                </ButtonContainer>
              </LeftSide>
            </Bounce>

            <Bounce right={!ar} left={ar}>
              <RightSide>
                <StyledImage
                  src={PexelsKarolinaGrabowska}
                  placeholderSrc={PexelsKarolinaGrabowskaLow}
                  height={622}
                  width={415}
                  alt="Pexels Karolina Grabowska - Ygii"
                />
              </RightSide>
            </Bounce>
          </Wrapper>
        </HomePageContainer>

        <SectionTwoContainer>
          <SectionTwo>
            <Slide bottom>
              <Text2>{t("partners")}</Text2>
            </Slide>
            <Slide bottom>
              <Sponsors>
                <SponsorImgFirst src={Partenr1} alt="Partner - Ygii" />
                <SponsorImg
                  src={Partner2}
                  alt="Partner - Ygii"
                  width={300}
                  $small
                />
                <SponsorImg src={Partner3} alt="Partner - Ygii" />
              </Sponsors>
            </Slide>
          </SectionTwo>
        </SectionTwoContainer>

        <Footer ar={ar} Slide={Slide} />
      </HomeContainer>
    </>
  );
};

export default HomePage;
