import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg";

export const HeaderContainer = styled.div`
  background-color: #85a1ac;
  display: flex;
  top: 0;
  flex-direction: row;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 7.5rem;
  max-height: 7.5rem;
  padding: 2rem;
  width: 1440px;
  gap: 1rem;
`;
export const FullNameText = styled.span`
  text-align: left;
  user-select: none;
  margin-bottom: -2px;
  margin-left: ${(props) => (props.$ar ? "0rem" : "2rem")};
  margin-right: ${(props) => (props.$ar ? "2rem" : "0rem")};
  color: #fff;
  font-family: "Avenir-Light";
  font-size: 18px;
  letter-spacing: -0.18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }

  @media (max-width: 870px) {
    display: none;
  }
`;
export const Logo = styled(LogoSvg)`
  @media (max-width: 410px) {
    width: 64.5px;
    height: 46.5px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => (props.$gap ? "2rem" : "0rem")};
  @media (max-width: 870px) {
    display: none;
  }
`;

export const ButtonLogin = styled.button`
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 106px;
  user-select: none;
  width: 119px;
  height: 49px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  @media (max-width: 410px) {
    font-size: 14px;
    height: 39px;
    width: 80px;
  }
`;
export const ButtonSignup = styled.button`
  color: #85a1ac;
  font-family: "Avenir-Medium";
  font-size: 18px;
  background: #fff;
  border: none;
  user-select: none;
  outline: none;
  cursor: pointer;
  border-radius: 106px;
  width: 119px;
  height: 49px;
  align-items: center;
  justify-content: center;
  @media (max-width: 410px) {
    font-size: 14px;
    height: 39px;
    width: 100px;
  }
`;

export const StyledDiv = styled.div`
  scale: 0.8;
  cursor: pointer;
  position: relative;
`;
export const MenuContainer = styled.div`
  margin-left: ${(props) => (props.$ar ? "unset" : "auto")};
  margin-right: ${(props) => (props.$ar ? "auto" : "unset")};

  display: none;
  width: 42px;
  height: 42px;
  align-items: center;
  z-index: 502;
  position: relative;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 870px) {
    display: flex;
    cursor: pointer;
  }
`;
export const Test = styled.span`
  font-size: 12px;
  user-select: none;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 1px;
  right: -5px;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  @media (max-width: 410px) {
    right: -12px;
    bottom: -2px;
  }
`;
export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  gap: 1rem;

  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const StyledText = styled.span`
  user-select: none;
  color: #fff;
  font-family: "Avenir-Medium";
  font-size: 18px;
  letter-spacing: -0.18px;
  min-width: fit-content;
  text-align: center;

  &:active {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;
export const Button = styled.button`
  cursor: pointer;
  border: unset;
  outline: unset;
  user-select: none;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  padding: 1rem 3rem;
  letter-spacing: 0.5%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 12.8rem;

  &:hover {
    box-shadow: 0px 1.2rem 2.4rem -0.6rem rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
  }
  &:active {
    transform: translate(0em, 0.2em);
  }
  @media (max-width: 450px) {
    min-width: 10rem;
  }
`;
