import { styled } from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;
export const StoreImage = styled.img`
  border-radius: 20px;
  height: 54px;
  width: 180px;
  user-select: none;
  @media (max-width: 800px) {
    width: 135px;
    height: 40.5px;
    border-radius: 15px;
  }
  @media (max-width: 350px) {
    width: 112.5px;
    height: 31.5px;
    border-radius: 10px;
  }
`;
export const Image = styled.img`
  user-select: none;
`;

export const Download = styled.div`
  height: 122px;
  width: 1440px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    height: 80px;
  }
  @media (max-width: 520px) {
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 1rem;
    gap: 1rem;
  }
`;
export const StyledDiv = styled.div`
  background-color: #85a1ac;
  width: 100%;
  justify-content: center;
  display: flex;
`;
export const Bg = styled.div`
  background: rgba(72, 72, 136, 0.05);
  width: 100%;
  justify-content: center;
  display: flex;
`;
export const Footer = styled.div`
  width: 1440px;
  padding: 2rem;
  gap: 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @media (max-width: 892px) {
    gap: 50px;
  }
`;

export const TitleDownload = styled.span`
  user-select: none;
  color: #85a1ac;
  font-family: "Avenir-Black";
  font-size: 40px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 610px) {
    font-size: 20px;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${(props) => (props.$ar ? "unset" : "auto")};
  margin-right: ${(props) => (props.$ar ? "auto" : "unset")};
  gap: 10px;
  @media (max-width: 520px) {
    justify-content: center;
    margin-left: unset;
    margin-right: unset;
  }
`;

export const TextFooter = styled.h3`
  color: #fff;
  user-select: none;
  font-family: "Avenir-Medium";
  width: 333px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.067px;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 150px;
  @media (max-width: 1100px) {
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 892px) {
    gap: 50px;
  }
`;
export const FooterColumn = styled.div`
  display: flex;
  gap: 19px;
  flex-direction: column;
  @media (max-width: 892px) {
    gap: 10px;
  }
  @media (max-width: 370px) {
    gap: 5px;
  }
`;
export const ColumTitle = styled.span`
  color: #fff;
  margin-bottom: 13px;
  user-select: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  letter-spacing: 0.067px;
  @media (max-width: 462px) {
    font-size: 14px;
  }
`;
export const ColumnLink = styled.span`
  color: #fff;
  font-family: "Avenir-Roman";
  font-size: 14px;
  user-select: none;

  cursor: pointer;
  letter-spacing: 0.067px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
  @media (max-width: 462px) {
    font-size: 12px;
  }
`;
export const ColumnLinkHref = styled.a`
  color: #fff;
  font-family: "Avenir-Roman";
  font-size: 14px;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.067px;
  &:hover {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
  }
`;
export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  gap: 10rem;
  @media (max-width: 555px) {
    gap: 5rem;
  }
  @media (max-width: 462px) {
    gap: 3rem;
  }
  @media (max-width: 370px) {
    gap: 1.5rem;
  }
`;
export const MediaContainer = styled.div`
  padding-bottom: 9px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Media = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
`;

export const CopyText = styled.span`
  color: #fff;
  font-family: "Avenir-Book";
  font-size: 10px;
  letter-spacing: 0.067px;
  user-select: none;
  padding-top: 2px;
`;

export const CopyContainer = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;
