import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedLocale } from "../../store/slices/common/slice";
import ArabicFlag from "../../../src/assets/images/arabicFlag.png";
import EnglishFlag from "../../../src/assets/images/englishFlag.png";
import { SESSION_STORAGE_SELECTED_LOCALE } from "../../utils/constants";
import {
  FlagIcon,
  LocaleButton,
  LocaleContainer,
} from "./LocaleSelection.styles";
import { useLocation } from "react-router-dom";

const LocaleSelection = ({ textColor, color, textOnly }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { selectedLocale } = useSelector((state) => state.commonInfo);
  const location = useLocation();

  const handleLocaleChange = (locale) => {
    localStorage.setItem(SESSION_STORAGE_SELECTED_LOCALE, locale);
    dispatch(setSelectedLocale(locale));
    i18n.changeLanguage(locale);

    const newPath = location?.search
      ? `/${locale}${location.pathname}${location.search}`
      : `/${locale}${location.pathname}`;
    window.history.replaceState({}, "", newPath);
    window.location.reload();
  };

  return (
    <>
      {!textOnly ? (
        <>
          {selectedLocale === "en" ? (
            <LocaleContainer onClick={() => handleLocaleChange("ar")}>
              <LocaleButton $color={color} $textColor={textColor}>
                {t("en")}
              </LocaleButton>
              <FlagIcon src={EnglishFlag} alt="English Flag" />
            </LocaleContainer>
          ) : (
            <LocaleContainer onClick={() => handleLocaleChange("en")}>
              <LocaleButton $color={color} $textColor={textColor}>
                {t("ar")}
              </LocaleButton>
              <FlagIcon src={ArabicFlag} alt="Arabic Flag" />
            </LocaleContainer>
          )}
        </>
      ) : (
        <>
          {selectedLocale === "en" ? (
            <LocaleContainer onClick={() => handleLocaleChange("ar")}>
              <LocaleButton $color={color} $textColor={textColor}>
                {t("english")}
              </LocaleButton>
            </LocaleContainer>
          ) : (
            <LocaleContainer onClick={() => handleLocaleChange("en")}>
              <LocaleButton $color={color} $textColor={textColor}>
                {t("arabic")}
              </LocaleButton>
            </LocaleContainer>
          )}
        </>
      )}
    </>
  );
};

export default LocaleSelection;
