import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import ar from "./locale/ar.json";
import { SESSION_STORAGE_SELECTED_LOCALE } from "../utils/constants";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: localStorage.getItem(SESSION_STORAGE_SELECTED_LOCALE) ?? "ar",
  fallbackLng: "ar",
  resources: {
    en,
    ar,
  },
});
