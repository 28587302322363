import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  LastNameInput,
  Row,
  SignupField,
  StyledCompanyIcon,
  StyledInput,
  StyledLockIcon,
  StyledMailIcon,
  StyledUserIcon,
  StyledVisibility,
  StyledVisibilityOff,
} from "./SignupPage.style";
import PhoneNumberInput from "../../../components/PhoneNumberInput/PhoneNumberInput";
import Icon from "../../../components/utils/Icon";

const SignUpFormComponent = ({
  organizationName,
  ar,
  setOrganizationName,
  setFirstName,
  firstName,
  lastName,
  setLastName,
  checkValidation,
  email,
  setEmail,
  visibility,
  visibility2,
  confirmPassword,
  password,
  setPassword,
  setConfirmPassword,
  setVisibility,
  setVisibility2,
  phoneNumber,
  setPhoneNumber,
}) => {
  const { t } = useTranslation();

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };

  const handleVisibilityToggle2 = () => {
    setVisibility2(!visibility2);
  };
  return (
    <Form>
      <SignupField>
        <Icon icon={<StyledCompanyIcon />} selectedLocale={ar} />

        <Input
          $ar={ar}
          type="text"
          $error={checkValidation && !organizationName}
          placeholder={t("organization-name")}
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
        />
      </SignupField>

      <Row>
        <SignupField>
          <StyledUserIcon $ar={ar} />
          <StyledInput
            $ar={ar}
            $error={checkValidation && !firstName}
            type="text"
            placeholder={t("first-name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </SignupField>
        <SignupField>
          <LastNameInput
            type="text"
            $error={checkValidation && !lastName}
            placeholder={t("last-name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </SignupField>
      </Row>

      <SignupField>
        <PhoneNumberInput
          phone={phoneNumber ?? ""}
          setPhone={setPhoneNumber}
          checkValidation={checkValidation}
        />
      </SignupField>
      <SignupField>
        <StyledMailIcon $ar={ar} />
        <Input
          $ar={ar}
          $error={checkValidation && !email}
          type="text"
          placeholder={t("email-address")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </SignupField>
      <SignupField>
        <StyledLockIcon $ar={ar} />
        <Input
          $ar={ar}
          type={visibility ? "text" : "password"}
          placeholder={t("password")}
          $error={checkValidation && !password}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {visibility ? (
          <Icon
            icon={<StyledVisibility onClick={handleVisibilityToggle} />}
            selectedLocale={ar}
          />
        ) : (
          <Icon
            icon={<StyledVisibilityOff onClick={handleVisibilityToggle} />}
            selectedLocale={ar}
          />
        )}
      </SignupField>
      <SignupField>
        <StyledLockIcon $ar={ar} />
        <Input
          $ar={ar}
          type={visibility2 ? "text" : "password"}
          placeholder={t("confirm-password")}
          $error={
            checkValidation &&
            (!confirmPassword || password !== confirmPassword)
          }
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {visibility2 ? (
          <Icon
            icon={<StyledVisibility onClick={handleVisibilityToggle2} />}
            selectedLocale={ar}
          />
        ) : (
          <Icon
            icon={<StyledVisibilityOff onClick={handleVisibilityToggle2} />}
            selectedLocale={ar}
          />
        )}
      </SignupField>
    </Form>
  );
};

export default SignUpFormComponent;
