import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, axiosAuthClient } from "../../api";
import { APP_VERSION } from "../../utils/constants";

import { setAccessToken } from "../slices/auth/slice";
import { LOGOUT } from "../constants";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL + "/api/",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().register.token;
    headers.set("Authorization", `Bearer ${token}`);
    headers.set("version", APP_VERSION);

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    try {
      const refreshResult = await axiosAuthClient.get("/refresh-token", {
        withCredentials: true,
      });

      if (refreshResult.data) {
        const { accessToken } = refreshResult.data.data;
        api.dispatch(setAccessToken({ accessToken }));

        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch({ type: LOGOUT });
      }
    } catch (err) {
      api.dispatch({ type: LOGOUT });
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["marketplaceItems", "categories"],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({}),
});
