import { createSlice } from "@reduxjs/toolkit";
import { signUpOrganizationBuilder } from "./builder";

const initialState = {
  organization: {},
  email: "",
  code: "",
  loggedIn: null,
  error: null,
  token: null,
  createAccountPending: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
    setEmailInput: (state, { payload }) => {
      state.email = payload;
    },
    setCodeInput: (state, { payload }) => {
      state.code = payload;
    },

    // setAccessToken: (state, { payload }) => {
    //   const { accessToken } = payload;
    //   state.token = accessToken;
    // },
  },
  extraReducers: (builder) => {
    signUpOrganizationBuilder(builder);

    builder.addCase("logout", () => ({ ...initialState, loggedIn: false }));
  },
});

export const { setLoggedIn, setEmailInput, setCodeInput, setAccessToken } =
  authSlice.actions;

export const selectLoggedIn = (state) => state.register.loggedIn;
export const selectEmailInput = (state) => state.register.email;
export const selectCodeInput = (state) => state.register.code;
export const selectCreateAccountPending = (state) =>
  state.register.createAccountPending;
export const selectOrganization = (state) => state.register.organization;

export default authSlice.reducer;
