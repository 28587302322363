import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../store/slices/auth/slice";
import commonSlice from "../store/slices/common/slice";
import { api } from "./api";

const store = configureStore({
  reducer: {
    register: authSlice,
    commonInfo: commonSlice,

    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
});

export default store;
